// Include default ABWeb CMS public styles
@import '../../../../../../webroot/assets/src/less/default.less';
// Custom variables
@import (optional) 'variables.less';
// Animations
@import (optional) 'animations.less';

@import 'slick.less';

/* -- Add site-specific styling below -- */

/*--------------------- TAGS */

h1, h2, h3, h4, h5, h6 {
    font-weight: @heading-weight;
}

h1 {
    text-transform: uppercase;
}

select option,
select optgroup {
    background: #fff;
    color: #333;
}

/*--------------------- HEADER */

header {
    .stuck-container {
        height: 100px;
        background: rgba(21, 12, 7, 0.7);

        &.isStuck {
            height: 70px;
            //background: rgba(42, 18, 10, 0.95);
            background: rgba(15, 15, 15, 0.94);
            z-index: 20000;
            transition: top 200ms ease-out;

            div.row > div {
                height: 70px;

                .logo-container {
                    height: 70px;
                    line-height: 70px;

                    img {
                        max-height: 60px;
                    }
                }
            }
        }

        div.row > div {
            position: relative;
            height: 100px;

            .logo-container {
                display: inline-block;
                height: 100px;
                line-height: 100px;

                a {
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            #main-nav {
                width: auto;
                position: absolute;
                top: 50%;
                margin-top: -18px;
                margin-bottom: 0;
                right: 0px;

                .navbar-nav {
                    li {
                        margin-left: 15px;

                        a {
                            border-radius: 15px;
                            font-size: 15px;
                            font-weight: 600;
                        }

                        .dropdown-menu {
                            background: #000;
                            padding: 15px 0px;

                            li {
                                margin-right: 15px;
                            }
                        }
                    }
                }

                @media screen and (max-width: 991px) {
                    .navbar {
                        overflow: visible;

                        .navbar-collapse {
                            overflow: hidden;
                            position: absolute;
                            top: @navbar-height;
                            border-top: 0;
                            transition: height 0.35s ease 0s;

                            left: -320px;
                            width: 320px;
                            height: 1000px;

                            max-height: 1%;
                            transition: none;
                            top: 55px;
                            border: none;

                            ul {
                                background: #000;
                            }

                            &.collapsing {
                                max-height: 250px;
                                overflow: hidden;
                            }

                            &.in {
                                max-height: 500px;
                                overflow: hidden;
                            }

                            .navbar-nav {
                                position: absolute;
                                top: 0px;
                                left: 0px;
                                height: auto;
                                width: 100%;
                                margin: 0px;

                                z-index: 1000;
                            }
                        }
                    }
                }
            }
        }
    }
}

body.cms-menu-visible {
    .stuck-container {
        margin-top: 0px;

        &.isStuck {
            margin-top: 50px;
        }
    }
}

.abwebcms-menu {
    z-index: 20001;
}

#debug-kit-toolbar {
    z-index: 20002 !important;
}

/*--------------------- CONTENT */

.normal-section,
.coloured-section {
    padding: 30px 0px;
}

.normal-section {
    background: #000;
}

.coloured-section {
    background: #402218;
}

.well {
    background: #2a120a;
    border: none;
}
.stamp {
    display: none;
}
#footerbanner,#headerbanner {
    position:relative;
    margin-left:auto;
    margin-right:auto;
    max-width:728px;
    width: 100%;
    margin-bottom:20px;
    margin-top:20px;
}
#homepageHeader{
    position:absolute;
    top:-168px;
    width: 100%;
    left: 0;
    padding: 0px 20px;

    @media (min-width: 447px) and (max-width: 767px) {
        top: -148px;
    }

    @media (min-width: 768px) {
        top: -132px;
    }
}

.imagefix img {
    max-width:100%
}
.left-content {
    width:~"calc(100% - 160px)";
    min-width:300px;
    margin-right:40px;
    position:relative;
    display:inline-block;
    float:left;
}
.right-advert {
    width:120px;
    position:relative;
    display:inline-block;
    float:left;
}
.page-header + p:last-child {
    margin-bottom: -15px !important;
}
.page-header + .row:last-child {
    margin-bottom: -15px !important;
}

#wrap .page-header {
    margin-bottom: 15px;
}

#wrap .page-header h1 {
    margin-top: 20px;
    padding-top: 10px;
}

#wrap #homepageHeader h1 {
    margin-top: 0px;
    padding-top: 0px;

    @media (max-width: 767px) {
        font-size: 1.75em;
    }
}

#wrap #homepageHeader h2 {
    @media (max-width: 767px) {
        font-size: 1.0em;
    }
}

#wrap #headerbanner {
    top: -40px;
    margin-bottom: -60px;
    position: relative;
    display: block;
    max-width: 728px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}
footer {
    .copyright {
        @media (max-width: 767px) {
            text-align: center;
            margin-top: 10px;
        }
    }
    #footerbanner {
        top: -15px;
        position: relative;
        display: block;
        width: 728px;
        max-width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}

.col-xs-5.col-sm-4 .article-image-back {
    max-width: 100%;
}

.page-header {
    border-bottom: none;

    &:first-child {
        margin-top: 0;

        h1 {
            margin-top: 0;
        }
    }

    h1,
    h3 {
        text-transform: uppercase;

        small {
            color: @heading-alt-colour;
            text-transform: none;
        }
    }

    h1 ~ h2,
    h3 ~ h4 {
        margin: -5px 0px 0px 0px;
        font-size: 1.2em;
        font-weight: 400;
    }

    .stamp {
        float: left;
        margin-right: 15px;
    }
}

.text-info {
    color: @heading-alt-colour;
}

/*--------------------- TABS */

.tab-content {
    .tab-pane {
        background: #2a120a;
        border: none;
    }

    .tab-no-border {
        padding: 15px 0px;
        background: none;
        border: none;
    }
}

.nav-tabs {
    border-bottom: 0px;

    & > li {
        margin-bottom: 0px;
        margin-right: 5px;
    }

    & > li > a {
        background: #1a0b06;
        border: none;
        color: #aa705f;

        &:hover {
            background: #2a120a;
        }
    }

    & > li > a.error {
        background: contrast(@main-back-colour, lighten(overlay(@main-back-colour, red), 7%), darken(overlay(@main-back-colour, red), 7%));
        color: #900;
        border-bottom: 1px solid contrast(@main-back-colour, lighten(@main-back-colour, 12%), darken(@main-back-colour, 12%));
    }

    & > li.active a,
    & > li.active a:hover,
    & > li.active a:focus {
        background: #2a120a;
        border: none;
        color: #e3e3e3;
    }

    .nav-tab-full-border.active {
        background: #2a120a;

        a,
        a:hover,
        a:focus {
            background: #2a120a;
        }
    }
}

/*--------------------- FORMS AND CONTROLS */

.form-control {
    background: none;
    color: @form-fore-colour;
    border: 0px;
    border-left: 2px solid #3d3531;
    border-bottom: 2px solid #3d3531;
    border-color: #3d3531 !important;
    box-shadow: none !important;
    font-weight: 400;

    & when (@flat-mode = true) {
        .flatten();
    }

    &::-moz-placeholder {
        color: @form-fore-colour;
        opacity: 1;
    }
    &::-ms-input-placeholder {
        color: @form-fore-colour;
        opacity: 1;
    }
    &::-webkit-input-placeholder {
        color: @form-fore-colour;
        opacity: 1;
    }
    &::-input-placeholder {
        color: @form-fore-colour;
        opacity: 1;
    }

    &:focus {
        border-color: @heading-alt-colour !important;

        &::-moz-placeholder {
            color: @heading-alt-colour;
        }
        &::-ms-input-placeholder {
            color: @heading-alt-colour;
        }
        &::-webkit-input-placeholder {
            color: @heading-alt-colour;
        }
        &::-input-placeholder {
            color: @heading-alt-colour;
        }
    }
}

.well,
.coloured-section {
    .form-control {
        border-color: #58413a !important;

        &:focus {
            border-color: @heading-alt-colour !important;
        }
    }

    .select2-container {
        .select2-selection {
            border-color: #58413a !important;
        }

        &:focus {
            border-color: @heading-alt-colour !important;
        }
    }
}

.has-success,
.has-warning,
.has-error {
    .select2-container {
        .select2-selection {
            border: 0px;
            border-left: 2px solid #3d3531;
            border-bottom: 2px solid #3d3531;
        }

        .select2-selection__arrow {
            display: none;
        }
    }
}

.has-success {
    .control-label {
        color: inherit;
    }

    .form-control-feedback {
        color: #62E664;
    }
}

.has-warning {
    .control-label {
        color: inherit;
    }

    .form-control-feedback {
        color: #ffc26b;
    }
}

.has-error {
    .control-label {
        color: inherit;
    }

    .form-control-feedback {
        color: #FF6966;
    }
}

fieldset {
    legend {
        border-bottom: 0;
        color: @heading-alt-colour;
    }
}

.select2-container {
    width: auto !important;

    .select2-selection {
        background: none;
        color: @form-fore-colour;
        border: 0px;
        border-left: 2px solid #3d3531;
        border-bottom: 2px solid #3d3531;
        border-color: #3d3531 !important;
        box-shadow: none !important;
        padding: 6px 12px;
        height: auto;

        & when (@flat-mode = true) {
            .flatten();
        }

        &::-moz-placeholder {
            color: @form-fore-colour;
            opacity: 1;
        }
        &::-ms-input-placeholder {
            color: @form-fore-colour;
            opacity: 1;
        }
        &::-webkit-input-placeholder {
            color: @form-fore-colour;
            opacity: 1;
        }
        &::-input-placeholder {
            color: @form-fore-colour;
            opacity: 1;
        }

        &.select2-selection--single {
            .select2-selection__rendered {
                line-height: inherit;
                padding: 0px;
                color: #e3e3e3;
            }

            .select2-selection__arrow {
                top: 50%;
                margin-top: -13px;

                b {
                    border-width: 6px 5px 0 5px;
                    border-style: solid;
                    border-color: #e3e3e3 transparent transparent transparent;
                    margin-left: -8px;
                }
            }
        }

        &.select2-selection--multiple {
            padding: 0px;
            border: 0px;
            border-left: 2px solid #3d3531;
            border-bottom: 2px solid #3d3531;

            .select2-selection__choice {
                background: lighten(#402218, 20%);
                color: #fff;
                border: 0px;
                padding: 2px 8px;

                span {
                    color: lighten(#402218, 50%);
                }
            }
        }

        &:focus {
            border-color: @heading-alt-colour !important;

            &::-moz-placeholder {
                color: @heading-alt-colour;
            }
            &::-ms-input-placeholder {
                color: @heading-alt-colour;
            }
            &::-webkit-input-placeholder {
                color: @heading-alt-colour;
            }
            &::-input-placeholder {
                color: @heading-alt-colour;
            }
        }
    }

    .select2-search__field {
        color: #333;
    }

    .select2-dropdown {
        & when (@flat-mode = true) {
            .flatten();
        }

        box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.5);

        .select2-results {
            .select2-results__option {
                background: #fff;
                color: #333;
                border-left: 5px solid #fff;

                &:hover {
                    background: #ddd;
                    color: #333;
                    border-left: 5px solid @select2-hover-back-colour;
                }
            }

            .select2-results__option[aria-selected=true] {
                background: #fff;
                color: #333;
                border-left: 5px solid @select2-selected-border-colour;

                &:hover {
                    background: #ddd;
                    color: #333;
                    border-left: 5px solid @select2-selected-border-colour;
                }
            }
        }
    }

    &.select2-container--open {
        .select2-selection {
            &.select2-selection--single {
                .select2-selection__arrow {
                    b {
                        border-width: 0 5px 6px 5px;
                        border-color: transparent transparent @heading-alt-colour transparent;
                    }
                }
            }
        }
    }
}

.actions {
    padding: 0;
    background: none;
    border: none;
}

.btn {
    &.btn-default {
        background: @heading-alt-colour;
        border: 1px solid lighten(@heading-alt-colour, 6%);

        &:hover {
            background: lighten(@heading-alt-colour, 5%);
            border: 1px solid lighten(@heading-alt-colour, 12%);
        }
    }

    &.btn-primary {
        background: #7f584b;
        border: 1px solid lighten(#7f584b, 6%);

        &:hover {
            background: lighten(#7f584b, 5%);
            border: 1px solid lighten(#7f584b, 12%);
        }
    }

    &.btn-success {
        background: #3d7a40;
        border: 1px solid lighten(#3d7a40, 6%);

        &:hover {
            background: lighten(#3d7a40, 5%);
            border: 1px solid lighten(#3d7a40, 12%);
        }
    }

    &.btn-danger {
        background: #7c3d3d;
        border: 1px solid lighten(#7c3d3d, 6%);

        &:hover {
            background: lighten(#7c3d3d, 5%);
            border: 1px solid lighten(#7c3d3d, 12%);
        }
    }

    &.btn-warning {
        background: #966d33;
        border: 1px solid lighten(#966d33, 6%);

        &:hover {
            background: lighten(#966d33, 5%);
            border: 1px solid lighten(#966d33, 12%);
        }
    }

    &.btn-info {
        background: #3e9296;
        border: 1px solid lighten(#3e9296, 6%);

        &:hover {
            background: lighten(#3e9296, 5%);
            border: 1px solid lighten(#3e9296, 12%);
        }
    }
}

.bootstrap-switch,
.bootstrap-switch.bootstrap-switch-on,
.bootstrap-switch.bootstrap-switch-off {
    border: 1px solid #000;

    .bootstrap-switch-label {
        .flatten();
        background: #633425;
    }

    .bootstrap-switch-handle-on,
    .bootstrap-switch-handle-off {
        &.bootstrap-switch-default {
            background: @heading-alt-colour;
        }

        &.bootstrap-switch-danger {
            background: #7c3d3d;
        }

        &.bootstrap-switch-success {
            background: #3d7a40;
        }
    }
}

.bootstrap-datetimepicker-widget {
    table {
        color: #333;
    }
}

.radio {
    .bootstrap-switch {
        margin-right: 10px;
        margin-left: -20px;
    }
}

.abwebcms-theme.froala-box {
    .froala-element.f-basic {
        background: #000;
        color: #e3e3e3;
    }
}

.pagination {
    margin: 0px;

    li > a {
        display: inline-block;
        width: 40px;
        height: 40px;
        margin-right: 10px;
        padding: 0px;
        line-height: 40px;
        text-align: center;
        border-radius: 20px !important;

        background: #1A0B06;
        color: #e3e3e3;
        border: none;

        &:hover,
        &:focus {
            background: @heading-alt-colour;
            color: #333;
        }
    }

    li.active > a {
        background: #402218;

        &:hover,
        &:focus {
            background: @heading-alt-colour !important;
            color: #333 !important;
        }
    }

    li:last-child > a {
        margin-right: 0px;
    }
}

/*--------------------- TABLES */

.table,
.table.abwebcms-table {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;

    tbody {
        tr {
            td,
            th {
                border-top: 1px solid #000;
                border-left: 1px solid #000;
            }

            th {
                background: #402218;

                &.yes {
                    background: #3d7a40 !important;
                }

                &.no {
                    background: #966d33 !important;
                }

                &.never {
                    background: #7c3d3d !important;
                }
            }

            td {
                background: #31140b;

                &.yes {
                    background: #3d7a40 !important;
                }

                &.no {
                    background: #966d33 !important;
                }

                &.never {
                    background: #7c3d3d !important;
                }
            }

            &:nth-child(2n+1) {
                th {
                    background: #402218;
                }

                td {
                    background: #1a0b06;
                }
            }
        }
    }
}

/*--------------------- BADGES AND STAMPS */

.label {
    border-radius: 5px;

    &.label-info {
        background: @heading-alt-colour;
        color: #000;
        font-size: 0.7em;
    }

    &.label-warning {
        background: #D68F2A;
    }

    &.label-danger {
        background: #cb2e28;
    }
}

.stamp {
    width: 59px;
    height: 59px;
    background: url('/img/icons.png') no-repeat top left;

    &.horse {
        background-position: 0px 0px;
    }

    &.horse-rider {
        background-position: -177px -59px;
    }

    &.heart {
        background-position: -59px 0px;
    }

    &.first-place {
        background-position: -118px 0px;
    }

    &.users {
        background-position: -236px -59px;
    }

    &.books {
        background-position: -118px -118px;
    }

    &.pill {
        background-position: -295px 0;
    }

    &.book {
        background-position: -236px 0;
    }
}

/*--------------------- PAGE ITEMS */

.page-item {
    .page-item-container {
        background: #31140b;
        border: 1px solid lighten(#31140b, 5%);
    }
}

/*--------------------- ALERTS */

.alert {
    color: #e3e3e3 !important;

    label {
        color: #e3e3e3 !important;
    }

    .form-control {
        border-color: #e3e3e3;
    }

    &.alert-info {
        background: lighten(#3e9296, 5%);
        border: 1px solid lighten(#3e9296, 12%);
    }

    &.alert-warning {
        background: lighten(#966d33, 5%);
        border: 1px solid lighten(#966d33, 12%);
    }

    &.alert-danger {
        background: lighten(#7c3d3d, 5%);
        border: 1px solid lighten(#7c3d3d, 12%);
    }

    &.alert-success {
        background: lighten(#3d7a40, 5%);
        border: 1px solid lighten(#3d7a40, 12%);
    }

    &.alert-fixed-top {
        transition: margin-top 200ms ease-out;
    }
}

body.stuck-menu-shown {
    .alert.alert-fixed-top {
        margin-top: 120px;
    }
}

/*--------------------- HOME PAGE */

.front-page-articles,
.internal-articles {
    position: relative;
    overflow: visible;

    .slick-arrow {
        background: url('/img/icons.png') no-repeat -38px -62px;
        border: none;
        width: 36px;
        height: 36px;
        position: absolute;
        top: -70px;
        right: 15px;
        outline: none;

        &:hover {
            background-position: -38px -98px;
        }

        &.slick-disabled {
            background-position: -38px -134px;
        }

        &.slick-prev {
            right: 70px;
            background-position: 0px -62px;

            &:hover {
                background-position: 0px -98px;
            }

            &.slick-disabled {
                background-position: 0px -134px;
            }
        }

        span {
            display: none;
        }
    }

    .item {
        position: relative;
        background: #2a120a;
        padding: 10px;
        height: 200px;
        overflow: hidden;
        cursor: pointer;
        transition: background-color 125ms ease;

        .article-date {
            display: block;
            float: right;
            width: 50px;
            height: 50px;
            margin: -10px -11px 0 0;
            background: #4d281c;
            transition: background-color 125ms ease;

            span {
                display: block;
                text-align: center;
                font-weight: bold;

                &:first-child {
                    margin-top: 5px;
                }
            }
        }

        .article-image-back {
            background: #2a120a;
            display: inline-block;
            transition: background-color 125ms ease;

            img {
                opacity: 1;
                transition: opacity 125ms ease;
            }
        }

        &:hover {
            background: #5e2f1f;

            .article-date {
                background: #754332;
            }

            .article-image-back {
                background: @heading-alt-colour;

                img {
                    opacity: 0.7;
                }
            }
        }
    }
}

.front-page-featured {
    .slick-arrow {
        background: url('/img/icons.png') no-repeat -38px -62px;
        border: none;
        width: 36px;
        height: 36px;
        position: absolute;
        top: -70px;
        right: 15px;
        outline: none;

        &:hover {
            background-position: -38px -98px;
        }

        &.slick-disabled {
            background-position: -38px -134px;
        }

        &.slick-prev {
            right: 70px;
            background-position: 0px -62px;

            &:hover {
                background-position: 0px -98px;
            }

            &.slick-disabled {
                background-position: 0px -134px;
            }
        }

        span {
            display: none;
        }
    }

    .item {
        display: block;
        position: relative;
        background: #2a120a;
        padding: 10px;
        height: auto;
        cursor: pointer;
        transition: background-color 125ms ease;
        color: #e3e3e3;
        text-decoration: none;

        span {
            display: block;
        }

        .ad-image-back {
            background: #2a120a;
            transition: background-color 125ms ease;

            img {
                opacity: 1;
                transition: opacity 125ms ease;
                margin: 0 auto;
            }
        }

        .ad-details {
            strong {
                display: block;
                text-align: center;
                margin-top: 5px;
            }

            span {
                display: block;
                text-align: center;
                margin-top: 0px;
            }
        }

        &:hover {
            background: #5e2f1f;

            .ad-image-back {
                background: #CFB463;

                img {
                    opacity: 0.7;
                }
            }
        }
    }
}

/*--------------------- NEWS */

.internal-articles {
    .item {
        margin-bottom: 10px;
    }
}

/*--------------------- ADVERTS */

.payment-table {
    .price {
        cursor: pointer;

        &:hover {
            background: #4f271a !important;
        }
    }
}

/*--------------------- DASHBOARD */

div.dashboard-tile {
    div.title {
        background: lighten(#402218, 15%);
    }

    div.logo {
        background: darken(#402218, 8%);
        color: @heading-alt-colour;
    }

    div.content {
        background: #31140b;
    }
}

/*--------------------- AD SERVER */

.ad-banner,
.ad-side {
    ins {
        width: 100%;

        a {
            display: block;
            width: 100%;

            img {
                display: block;
                max-width: 100%;
                height: auto;
            }
        }
    }
}

/*--------------------- MEDIA */

.media {
    .media-item {
        .image {
            background: lighten(#4f271a, 5%);
            border: 1px solid lighten(#4f271a, 10%);
        }

        .tags {
            background: lighten(#4f271a, 1%);
        }

        .time {
            background: darken(#66afe9, 35%);

            &.unpublished {
                background: darken(#E9AA62, 35%);

                .icon {
                    color: contrast(darken(#E9AA62, 35%), lighten(darken(#E9AA62, 35%), 20%), darken(darken(#E9AA62, 35%), 20%));
                }
            }

            &.expired {
                background: darken(#D35757, 35%);

                .icon {
                    color: contrast(darken(#D35757, 35%), lighten(darken(#D35757, 35%), 20%), darken(darken(#D35757, 35%), 20%));
                }
            }
        }
    }
}

.modal {
    top: 95px;
}

.modal-body {
    .nav-tabs {
        border-bottom: 1px solid #ddd;

        & > li {
            margin-bottom: -1px;
        }

        & > li > a {
            background: #ddd;
        }

        & > li.active > a {
            color: #555;
            background-color: #F4F4F4;
            border: 1px solid #ddd;
            border-bottom-color: #F4F4F4;
        }
    }

    .tab-content {
        .tab-pane {
            background: #F4F4F4;
            border: 1px solid #DDDDDD;
            border-top: 0px;
        }
    }
}
