/*
ABWEB CMS STYLE CONFIGURATION

This LESS file contains many variables used to make deploying styles to a new installation of ABWeb CMS easy. It's best to use this file to cover as much
group style-wise as possible, before editing the style.less file for additional changes and extra elements not covered in ABWeb CMS
*/

/*--------------------- MAIN */

@main-back-colour: #000000;
@main-fore-colour: #e3e3e3;

/*--------------------- TYPOGRAPHY */

@use-google-fonts: true;                // If true, will use an external Google Fonts stylesheet to provide the fonts below

@content-font: 'Open Sans';
@content-typography: sans-serif;
@content-normal-weight: 400;
@content-bold-weight: 700;
@content-size: 14px;                    // This represents the default size of text on the page. All other font sizes are derived from this.

@heading-font: 'Open Sans';
@heading-typography: sans-serif;
@heading-weight: 300;
@heading-colour: #e3e3e3;               // Used on H1, H3 and H5 tags
@heading-alt-colour: #cfb463;           // Used on H2, H4 and H6 tags

@include-additional-font:           true;                  // If you wish to include a third font, set this to true and set the values below
@additional-font:                   'Open Sans';
@additional-typography:             sans-serif;
@additional-normal-weight:          300;
@additional-bold-weight:            600;
@additional-include-italic-font:    false;                  // Set to true to load the italic and bold-italic version of this font as well

@monospace-font: 'Droid Sans Mono';
@monospace-typography: monospace;

/*--------------------- HEADER */

@header-back-colour: #111111;
@header-fore-colour: #fafafa;
@header-height: 150px;
@header-bottom-spacing: 0px;

@header-show-top-border: false;         // A border at the top of the page helps users with touch devices by giving them feedback showing they are at
                                        // the top of the page. Set to false to disable this. (deprecated)
@header-top-border-colour: #541C51;

@header-use-logo: true;                // When true, the H1 tag in the header will be replaced with a site logo that can be specified below
@header-logo-image: 'included/abwebcms-logo.png';                 // Image file to use as logo. Must reside in the /app/webroot/img folder or one of its subfolders
@header-logo-position: 'left';        // Set the alignment of the logo or site name (use left, right or centre)
@header-logo-width: 238px;
@header-logo-height: 77px;

@header-use-background-image: true;     // When true, the background of the header will be an image resized to fit within the constrains of the header
@header-background-image: 'background-1.jpg';           // Image file to use as background. Must reside in the /app/webroot/img folder or one of its subfolders

/*--------------------- HOME PAGE */

@home-page-jumbo: true;                 // If true, will resize the header area to be large on the front page for more visual impact
@home-page-header-height: 250px;
@home-page-header-logo-width: 238px;
@home-page-header-logo-height: 77px;

/*--------------------- NAVIGATION */

@navbar-position: 'top';
@navbar-back-colour: transparent;
@navbar-fore-colour: #e3e3e3;
@navbar-height: 36px;
@navbar-item-padding: 15px;
@navbar-font-size: 1.15em;
@show-brand: true;                     // Brand is usually used to show a "Home" page link, but could also contain the site name

@navbar-hover-back-colour: #cfb463;
@navbar-hover-fore-colour: #000;

@navbar-active-back-colour: #cfb463;
@navbar-active-fore-colour: #000;

// Dropdown (hover and active states copied from main nav)
@dropdown-back-colour: darken(@navbar-back-colour, 10%);
@dropdown-fore-colour: @navbar-fore-colour;

// Submenu found on page
@submenu-back-colour: #f4f4f4;
@submenu-fore-colour: #333333;

@submenu-hover-back-colour: contrast(@submenu-back-colour, lighten(@submenu-back-colour, 15%), darken(@submenu-back-colour, 15%));
@submenu-hover-fore-colour: contrast(@submenu-hover-back-colour);

@submenu-active-back-colour: #541C51;
@submenu-active-fore-colour: contrast(@submenu-active-back-colour);

/*--------------------- FOOTER */

@sticky-footer: true;                   // If true, the footer will stick to the bottom of the page

@footer-back-colour: #000000;
@footer-fore-colour: #e3e3e3;
@footer-height: 60px;

/*--------------------- LINKS */

@link-colour: @heading-alt-colour;
@link-hover-colour: #ffffff;
@link-show-underline: true;

/*--------------------- FORMS */

@form-fore-colour: @main-fore-colour;
@form-back-colour: @main-back-colour;
@form-border-colour: darken(@form-back-colour, 25%);

@select2-hover-fore-colour: #fff;
@select2-hover-back-colour: #5897fb;

@select2-selected-fore-colour: @form-fore-colour;
@select2-selected-back-colour: darken(@form-back-colour, 5%);
@select2-selected-border-colour: #57bf2d;

/*--------------------- ABWEB CMS */

@abweb-orange: #E5A220;
@abweb-grey: #444444;
@abweb-light-grey: #C7CBCD;

/*--------------- ABWEB CMS INTERNALS */

@abwebmenu-back-colour: #282828;
@abwebmenu-fore-colour: #dddddd;
@abwebmenu-active-back-colour: #444444;
@abwebmenu-active-fore-colour: #ffffff;

@abweb-table-border: #000;
@abweb-table-th-back: #402218;
@abweb-table-hover: #31140b;

/*--------------------- UTILITIES */

@flat-mode: true;                       // If true, this will remove a lot of the gradients, border radii and shadows used on various
                                        // Bootstrap components, to give a flat look

@debranded: false;                       // If true, any styles and logos that make ABWeb CMS branded to ABWeb will be removed
